.quick-filter-wrapper {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 15px 25px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid transparent;
  position: relative;
  backdrop-filter: blur(6px);
  z-index: 3;
  @media (min-width: 768px) {
    border-radius: 90px;
  }
  @media (max-width:767px) {
    padding-bottom: 24px;
  }
}

.quick-filter-wrapper .dropdown-menu.calender-menu {
  --bs-dropdown-bg: transparent;
  --bs-dropdown-border-width: 0px;
}

.quick-filter-wrapper .col-2.col {
  height: 100%;
  padding: 10px;
}

.quick-filter-wrapper .col-2 {
  flex: 1 1 auto;
}

.quick-filter-wrapper .col-1.col {
  display: flex;
  justify-content: center;
  border-left: 1px solid var(--darkgray);
  height: 100%;
  padding: 10px;
}

.quick-filter-wrapper .for-large-screen {
  width: 100%;
  align-items: center;
  position: relative;
  border-left: 0 !important;
  .icon {
    margin: 0 5px 0 7px;
  }
  .text {
    // width: 100%;
    padding-right: 7px;
  }
  &::before {
    border-left: 1px solid var(--darkgray);
    content: "";
    position: absolute;
    left: 0;
    width: 0;
    height: 65px;
  }
}

.depart-from {
  display: grid;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

#darkMode .quick-filter-wrapper .for-large-screen hr {
  border-color: var(--white);
}

.quick-filter-wrapper .col-1 {
  flex: 0 0 240px;
  max-width: 240px;
}

.quick-filter-wrapper .check-in,
.quick-filter-wrapper .check-out {
  cursor: pointer;
}

.calendarBtns {
  position: absolute;
  z-index: 4;
  top: 362px;
  display: flex;
  justify-content: center;
  width: 100%;
  left: 7px;
}
.quick-filter-wrapper .icon svg {
  font-size: 18px;
  color: var(--darkgray);
  margin-right: 12px;
  display: inline-block;
  margin-top: 2px;
}

.quick-filter-wrapper .serach-action {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 100%;
  cursor: pointer;
}

.quick-filter-wrapper .for-small-screen.col.col-1 {
  display: none;
}

#darkMode .quick-filter-wrapper {
  background-color: rgba(0, 0, 0, 0.6);
  border: 1px solid var(--gray);
}

#darkMode .quick-filter-wrapper .text h6 {
  // color: #bdc6d7;
  color: var(--white);
}

#darkMode .quick-filter-wrapper .icon svg {
  color: var(--white);
}

// Persions Dropdown

.traveler .dropdown .dropdown-toggle {
  background-color: var(--transparent);
  outline: none !important;
  border: none;
  margin: 0;
  font-size: var(--default-font-size);
  box-shadow: none !important;
  padding: 0;
  position: relative;
  color: var(--white);
  display: flex;
  justify-content: center;
  text-align: left;
}

.traveler .dropdown .dropdown-toggle .traveler-section {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.traveler .dropdown .dropdown-toggle:hover,
.traveler .dropdown .dropdown-toggle:active,
.traveler .dropdown .dropdown-toggle:focus {
  color: #fff;
  background-color: var(--transparent) !important;
  border-color: var(--transparent);
  box-shadow: none;
}

.traveler .dropdown-toggle::after {
  display: none;
}

.traveler .dropdown-menu {
  min-width: 320px;
  padding: 25px;
  border: none;
  border-radius: 12px;
}

.traveler .dropdown-menu .d-titlle {
  color: var(--green);
  font-family: var(--noto);
  font-weight: var(--bold);
  font-size: 16px;
  text-transform: capitalize;
}

.traveler li {
  display: flex;
  color: var(--default-color);
  text-align: inherit;
  background-color: transparent;
  border: 0;
  font-size: var(--default-font-size);
  padding: 0;
  align-items: center;
  margin-bottom: 15px;
}

.traveler li .caption {
  flex: 0 0 50%;
  max-width: 50%;
  text-transform: capitalize;
}

.traveler li .caption p {
  margin: 0;
}

.traveler li .qty {
  flex: 0 0 50%;
  max-width: 50%;
  text-align: right;
}

.traveler li .qty .qty-val {
  max-width: 40px;
  padding: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  display: inline-block;
  width: 100%;
}

.traveler li .qty .qty-action {
  border: 5px solid #182c3d;
  display: inline-block;
  background: #4c6180;
  width: 30px !important;
  height: 30px !important;
  text-align: center;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 100%;
  font-size: 8px;
  color: #fff;
  line-height: 20px;
  cursor: pointer;
}

.addmore-action {
  text-align: center;
}

.traveler .dropdown .addmore-action a {
  background-color: #1f6de7;
  font-size: 13px;
  color: #fff;
  padding: 7px 15px;
  text-align: center;
  border-radius: 50px;
  margin: 0 5px;
  &:first-child {
    background-color: var(--green);
  }
}

#darkMode .traveler .dropdown-menu {
  background-color: #091e30;
}

#darkMode .traveler li .caption p,
#darkMode .traveler li .qty .qty-val {
  color: #fff;
}

.calendar-wrapper .reset-btn {
  // position: absolute;
  z-index: 4;
  // top: 362px;
  color: var(--default-color);
  border: 1px solid var(--green);
  padding: 5px 20px;
  border-radius: 41px;
  margin: 0 5px;
  // right: 85px;
  &.select {
    color: white;
    background-color: var(--green);
    border: 1px solid var(--green);
    padding: 5px 20px;
    border-radius: 40px;
    // right: -15px;
  }
}
#darkMode .calendar-wrapper .reset-btn {
  color: #ffffff;
}

.stars-range-wrapper {
  position: relative;
  padding: 10px 30px !important;
  @media (max-width:991px) {
    padding: 10px 12px !important;
  }
  @media (max-width:767px) {
    padding: 10px !important;
  }
  &::before {
    border-left: 1px solid var(--darkgray);
    content: "";
    position: absolute;
    left: 0;
    width: 0;
    height: 65px;
    @media (max-width:767px) {
      display: none;
    }
  }
  .range-bar {
    .range-value {
      min-width: 20px;
      &.highest {
        text-align: end;
      }
    }
  }
}
.filter-star-rating {
  margin: 25px -10px 0 -10px !important;
  > div {
    color: #777e90;
    font-size: 19px;
    position: relative;
    &.filled {
      color: #ffc500;
      span {
        color: #000;
      }
    }
    span {
      font-size: 10px;
      position: absolute;
      top: 0;
      left: 0;
      color: #fff;
      z-index: 9;
      width: 22px;
      height: 26px;
      align-items: center;
      justify-content: center;
      display: flex;
      font-family: var(--noto);
    }
  }
}

@media only screen and (max-width: 1199px) and (min-width: 768px) {
  .search-page {
    .quick-filter-wrapper .col-2 {
      flex: 0 0 100%;
      margin-bottom: 10px;
      .rangeslider {
        max-width: 60%;
        margin: auto;
      }
    }
    .quick-filter-wrapper .col-1 {
      flex: 0 0 220px;
      max-width: 220px;
    }
    .quick-filter-wrapper .serach-action {
      margin: auto;
      margin-bottom: -53px;
      margin-top: 25px;
    }
    .no-border {
      border-left: 0 !important;
    }
    .traveler .dropdown-menu {
      left: 0 !important;
    }
  }
  .traveler .dropdown-menu {
    left: auto !important;
    right: 0 !important;
  }
}
@media only screen and (max-width: 1199px) {
  .for-small-screen.for-small-screen.col.col-1 {
    display: flex;
  }
  .for-large-screen.col.col-1 {
    display: none;
  }
  .calendar-dropdown {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .search-page .quick-filter-wrapper .col-1 {
    flex: 0 0 160px;
    max-width: 160px;
  }
}

@media only screen and (max-width: 991px) {
  .quick-filter-wrapper .text h6 {
    font-size: 16px;
  }
  .quick-filter-wrapper .col-1 {
    flex: 0 0 170px;
    max-width: 170px;
  }
  .quick-filter-wrapper .text p {
    font-size: 12px;
  }
  .quick-filter-wrapper .serach-action {
    display: inline-block;
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
  .traveler .dropdown-menu {
    min-width: 290px;
    padding: 15px;
    // left: auto !important;
    right: 0 !important;
    // transform: translate3d(0px, 95px, 0px) !important;
  }
  .traveler .dropdown-item .qty .qty-val {
    max-width: 30px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .quick-filter-wrapper .col-2 {
    flex: 0 0 100%;
    width: 100%;
  }
  .search-page .quick-filter-wrapper .serach-action {
    bottom: -35px;
  }
  .quick-filter-wrapper .text p {
    color: #596c7fbf;
  }
  #darkMode .quick-filter-wrapper .text p {
    color: var(--white);
  }
  .quick-filter-wrapper .text h6 {
    font-size: 15px;
  }
  .traveler .dropdown-menu {
    left: auto !important;
    right: 0 !important;
    transform: translate3d(0px, 95px, 0px) !important;
  }
  .home-quick .traveler .dropdown-menu {
    left: 0 !important;
  }
  .calendar-wrapper .calendarBtns {
    top: 560px;
  }
  // .calendar-wrapper .reset-btn {
  //   // top: 606px;
  //   top: 560px;
  // }
  .calendar-wrapper .rdrDateRangePickerWrapper {
    // min-height: 640px !important;
    min-height: 595px !important;
  }
  .calendar-wrapper .rdrCalendarWrapper {
    padding: 10px !important;
  }
  .rdrCalendarWrapper {
    .rdrMonths {
      &.rdrMonthsVertical {
        .rdrMonth {
          padding-bottom: 13px;
        }
      }
    }
  }
  .quick-filter-wrapper .col-1 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .quick-filter-wrapper .col-1.col {
    border-left: none;
    justify-content: flex-start;
    padding: 20px 10px;
  }
  .quick-filter-wrapper .serach-action {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    a {
      font-size: 17px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .quick-filter-wrapper {
    padding: 15px 15px;
    border-radius: 25px;
    padding-bottom: 30px;
  }
  .quick-filter-wrapper .text p {
    font-size: 11px;
  }
}

// Date Ranger picker className
.calendar-wrapper .rdrDateRangePickerWrapper {
  margin-top: 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  min-height: 400px;
}

.calendar-wrapper .rdrDefinedRangesWrapper {
  display: none;
}

.calendar-wrapper .rdrMonthName {
  text-align: center;
  font-weight: 600;
  font-family: var(--poppings);
  color: var(--green);
}

.calendar-wrapper .rdrNextPrevButton {
  width: auto;
  height: auto;
  background: transparent;
}

.rdrNextPrevButton:hover {
  background: transparent !important;
}

.calendar-wrapper button.rdrDay.rdrDayPassive {
  opacity: 0;
}

#darkMode .calendar-wrapper .rdrPprevButton i {
  border-color: transparent #ffffff transparent transparent;
}

#darkMode .calendar-wrapper .rdrNextButton i {
  border-color: transparent transparent transparent #fff;
}

#darkMode .rdrMonthAndYearPickers {
  color: #fff;
}

.calendar-wrapper .rdrCalendarWrapper {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  padding: 10px 25px;
}

#darkMode .calendar-wrapper .rdrCalendarWrapper {
  background: #091e30;
}

#darkMode .calendar-wrapper .rdrCalendarWrapper .rdrDayDisabled {
  // background-color: transparent;
  background-color: rgba(248, 248, 248, 0.08);
}

.calendar-wrapper .rdrWeekDay {
  font-weight: 400;
  line-height: 1.4;
  color: var(--gray);
}

.calendar-wrapper .rdrDayPassive .rdrDayNumber span {
  color: var(--gray) !important;
}

.calendar-wrapper .rdrDayNumber span {
  color: var(--default-color);
  font-size: 14px;
  font-weight: 600;
  font-family: var(--poppings);
}

#darkMode .calendar-wrapper .rdrDayNumber span {
  color: var(--white);
}
#darkMode
  .calendar-wrapper
  .rdrCalendarWrapper
  .rdrDayDisabled
  .rdrDayNumber
  span {
  // #darkmode .calendar-wrapper .rdrDayDisabled .rdrDayNumber span {
  color: var(--lightgray) !important;
  font-weight: 400;
}
.calendar-wrapper .rdrInRange,
.calendar-wrapper .rdrEndEdge,
.calendar-wrapper .rdrStartEdge {
  color: #05ce78 !important;
}

.calendar-wrapper .dateDot {
  height: 5px;
  width: 5px;
  border-radius: 100%;
  background: orange;
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
}

.calendar-wrapper .rdrDayDisabled .rdrDayNumber span {
  color: var(--lightgray);
  font-weight: 400;
}

.room-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.room-title a {
  color: red !important;
  font-size: 16px;
}
