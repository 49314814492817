.loader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease 0s;
}
.loader.show {
    opacity: 1;
    visibility: visible;
}
.loader:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba(255, 255, 255, 0.6);
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(6px);
}
#darkMode .loader:before {
    background-color: rgba(0, 0, 0, 0.6);
}
.loader-content {
    position: relative;
    z-index: 1;
    text-align: center;
}
.loader-content p {
    font-size: 24px;
    margin: 0;
    color: var(--gray);
    font-weight: 700;
    font-family: var(--noto);
}
.loader_spinner {
    width: 30%;
}
.reset-btn {
    cursor: pointer;
    font-size: 18px;
    color: var(--white);
    position: absolute;
    top: 10%;
    right: 10%;
    background-color: var(--green);
    padding: 10px 20px;
    border-radius: 16px;
}
