#top-section {
    padding-top: 50px;
    position: relative;
    z-index: 4;
}
#top-section  .content-wrapper{
    position: relative;
}
#top-section .image-holder {
    margin: 0 15px 0 35px;
}
#top-section .floating-image {
    position: absolute;
    right: -10px;
    top: 40px;
    display: none;
    // z-index: 2;
}
#top-section .content-hodler .subtitle {
  background-color: var(--orange);
  display: inline-block;
  padding: 10px 15px;
  text-transform: uppercase;
  border-radius: 6px;
  margin-bottom: 5px;
}
#top-section .content-hodler .subtitle img {
  margin-right: 15px;
}
#darkMode #top-section .content-hodler p{
    color: var(--lightgray);
}
@media only screen and (max-width: 1199px){
    #top-section .image-holder {
        display: none;
    }
    #top-section .floating-image{
        display: block;
    }
}
@media only screen and (max-width: 991px){
    #top-section .floating-image {
        top: 122px;
    }
    #top-section .heading-text{
        width: 70%;
    }
    #top-section .heaidng{
        width: 70%;
        line-height: 68px;
    }
}
@media only screen and (max-width: 767px){
    #top-section .floating-image {
        display: none;
    }
    #top-section .content-hodler .subtitle {
        font-size: 11px;
    }
    #top-section .content-hodler .subtitle img {
        margin-right: 7px;
        max-width: 30px ;
    }
    #top-section .heading-text{
        width: 100%;
    }

    #top-section .heaidng{
        width: 100%;
        line-height: 60px;
        font-size: 45px;
    }
}

@media only screen and (max-width: 576px){
    #top-section .heading-text{
        width: 100%;
    }
    #top-section .heaidng{
        width: 100%;
        line-height: 56px;
        font-size: 40px;
    }
}